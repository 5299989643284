import React from "react"
import Layout from "../components/layout"
import styled from "styled-components"
import BlockContent from "@sanity/block-content-to-react"
import { Headers, Main, ParsWrapper } from "../components/typography"

const Curriculum = ({ data }) => {
  const titleArray = data.sanityCurriculumPost.sectionDesc
  const pdfArray = data.sanityCurriculumPost.sectionDesc
  const pdfTitleArray = data.sanityCurriculumPost.sectionDesc

  const serializers = {
    types: {
      code: props => (
        <pre data-language={props.node.language}>
          <code>{props.node.code}</code>
        </pre>
      ),
    },
  }
  return (
    <Layout>
      <Main>
        {data.sanityCurriculumPost._rawSectionDesc.map((block, i) => {
          return (
            <>
              <Headers>{titleArray[i]["title"]}</Headers>
              <ParsWrapper>
                <BlockContent
                  blocks={block.content}
                  serializers={serializers}
                />
              </ParsWrapper>

              {pdfArray[i]["pdfUpload"] && (
                <a href={pdfArray[i]["pdfUpload"]["asset"]["url"]}>
                  {pdfTitleArray[i]["pdfTitle"]}
                </a>
              )}
            </>
          )
        })}
      </Main>
    </Layout>
  )
}

export default Curriculum

export const query = graphql`
  query CurriculumQuery {
    sanityCurriculumPost {
      _rawSectionDesc(resolveReferences: { maxDepth: 10 })
      sectionDesc {
        title
        pdfTitle
      }
    }
  }
`
