import React from "react"
import styled from "styled-components"

export const Main = styled.div`
  @media (min-width: 1440px) {
    width: 1440px;
    margin: 75px auto;
  }
  @media (min-width: 1280px) {
    //width: 1280px;
    margin: 50px auto;
  }
`
export const Headers = styled.h1`
  font-size: 3.2rem;
  @media (min-width: 768px) {
    font-size: 5rem;
  }
`
export const ParsWrapper = styled.div`
  p,
  li {
    font-size: 1.8rem;
    @media (min-width: 768px) {
      width: 65%;
    }
    line-height: 34px;
  }
  h1 {
    font-size: 4rem;
    @media (min-width: 768px) {
      font-size: 5rem;
    }
  }
  h2 {
    font-size: 3rem;
    @media (min-width: 768px) {
      font-size: 4rem;
    }
  }
  h3 {
    font-size: 2rem;
    @media (min-width: 768px) {
      font-size: 3rem;
    }
    @media (min-width: 768px) {
      width: 65%;
    }
    line-height: 52px;
  }
  h4 {
    font-size: 2rem;
    @media (min-width: 768px) {
      width: 65%;
    }
    line-height: 42px;
  }
  h5 {
    font-size: 1.6rem;
    @media (min-width: 768px) {
      width: 65%;
    }
    line-height: 32px;
  }
`
